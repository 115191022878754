@import "./tailwind.css";

@import "./1_settings/sizes.css";

@import "./2_tools/mixins.css";
@import "./2_tools/magnific.css";

@import "./3_generic/shared.css";

@import "./4_elements/typography.css";
@import "./4_elements/generic.css";
@import "./4_elements/form.css";
@import "./4_elements/table.css";

@import "./5_atoms/wrapper.css";
@import "./5_atoms/form.css";
@import "./5_atoms/btn.css";

@import "./6_components/site/header.css";
@import "./6_components/site/footer.css";
@import "./6_components/breadcrumb.css";
@import "./6_components/hero.css";
@import "./6_components/secondary-hero.css";
@import "./6_components/faqs.css";
@import "./6_components/stats.css";
@import "./6_components/colour-cards.css";
@import "./6_components/page-banner.css";
@import "./6_components/news-cards.css";
@import "./6_components/featured-news-cards.css";
@import "./6_components/search-bar.css";
@import "./6_components/search-results.css";
@import "./6_components/campaign-cards.css";
@import "./6_components/download-cards.css";
@import "./6_components/people-cards.css";
@import "./6_components/detail-card.css";
@import "./6_components/event-cards.css";
@import "./6_components/event-info.css";
@import "./6_components/text-image.css";
@import "./6_components/pagination.css";
@import "./6_components/form-designer.css";
@import "./6_components/two-col-text.css";
@import "./6_components/video-block.css";
@import "./6_components/quote.css";
@import "./6_components/donation-banner.css";
@import "./6_components/logo-carousel.css";
@import "./6_components/gallery.css";
@import "./6_components/slick.css";
@import "./6_components/text-block.css";
@import "./6_components/children-awards.css";
@import "./6_components/newsletter.css";
@import "./6_components/news-details.css";



/* @import "./7_overrides/override.css"; */
